import { OPEN_DIALOG } from "../../../redux/modules/actionTypes";
import { EventTrackingProps, EventWSBSources } from "../../EventTracking/constants";
import { EventsRegistryType } from "../../EventTracking/types";
import { PAGE_TREE_PAGE_DELETE_CONFIRM } from "../../PagesTree/Tree/actionTypes";
import { blogWizardCompletionHandler, blogWizardInitiationHandler } from "./eventsPropsHandler";
import BlogOnboardingWizardDialogId from "./OnboardingWizard/dialogId";
import { BLOG_WIZARD_COMPLETED, BLOG_UPGRADE_CLICKED } from "./OnboardingWizard/epic/actionTypes";

const BlogEvents = {
    blogWizardInitiation: "WSB Blog wizard initiated",
    blogWizardCompletion: "WSB Blog wizard completed",
    blogUpgradeClicked: "WSB upgrade button for Blog component",
    blogPageDeleted: "WSB Blog page is deleted"
};

const blogPageType = "Blog";

const BlogMixpanelEvents: EventsRegistryType = {
    [OPEN_DIALOG]: {
        getEventName: (_, payload) => payload.dialogId === BlogOnboardingWizardDialogId ? BlogEvents.blogWizardInitiation : null,
        propsHandler: blogWizardInitiationHandler
    },
    [BLOG_WIZARD_COMPLETED]: {
        eventName: BlogEvents.blogWizardCompletion,
        propsHandler: blogWizardCompletionHandler
    },
    [BLOG_UPGRADE_CLICKED]: {
        eventName: BlogEvents.blogUpgradeClicked,
        propsHandler: (_, payload) => ({
            [EventTrackingProps.source]: payload.source
        })
    },
    [PAGE_TREE_PAGE_DELETE_CONFIRM]: {
        getEventName: (_, payload) => payload?.pageType === blogPageType ? BlogEvents.blogPageDeleted : null,
        propsHandler: () => ({ [EventTrackingProps.source]: EventWSBSources.PageTree })
    }
};

export {
    BlogMixpanelEvents,
    blogPageType,
};
