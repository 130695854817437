// @ts-nocheck
/* eslint-disable max-len */

import React from "react";
import * as R from "ramda";
import { getDAL } from "../../../../../dal/index";
import getQueryData from "./getQueryData";
import { ProductListStyles, WEBSHOP_MIN_HEIGHT } from "../constants";
import { checkIfCartComponentIsNotShown, getWebshopV2BaseUrl } from "../utils";
import { getDefultLocaleIdNormalized } from "../../../Locale/getDefaultLocale";
import type { ThemeColorDataType } from "../../../ThemeGlobalData/flowTypes";
import { getThemeColorsFromStylesheet, textNormalFont } from "../../../Workspace/epics/stylesheets/selectors";
import { getThemeRulesForWebshop } from "../../../ThemeGlobalData/themeRules";
import actionTypes from "../reducer/actionTypes";

const DEFAULT_LOCALE_NORMALIZED = getDefultLocaleIdNormalized();
const WebshopComponent = props => {
    const iframeRef = React.useRef(null);
    const [isShopIframeLoadingCompleted, setIsShopIframeLoadingCompleted] = React.useState(false);
    const globalTextFont = textNormalFont(props.globalStyles);

    const {
            component,
            isServerPreview,
            isMVEFocus,
            currentPageId,
            templateSelectorPreviewMode,
            previewBackupTime,
            uiLocale,
            siteMap,
            siteSettings,
            heightFromWS,
            globalStyles,
            themeSettingsData: { autoColorMode },
            selectedParentTheme,
            webshopAdminDialog = { iframeRefreshCounter: 0 },
            featuredProduct,
            isWorkspace,
            timestamp = 0,
            config,
            isPublicPreview,
            dispatch
        } = props,
        { iframeRefreshCounter } = webshopAdminDialog,
        escapeQuotes = text => {
            return text ? text.replace(/["']/g, "&#39;") : "";
        };

    const sendStyle = () => {
        if (typeof iframeRef.current?.contentWindow?.postMessage === "function") {
            const webshopPreviewStyles = `${props.buttonStyle || ""} ${props.buyNowButtonStyle || ""} ${props.linkStyle || ""}`.replace(
                /\n/g,
                ""
            );
            iframeRef.current.contentWindow.postMessage(
                {
                    type: "RECEIVE_WEBSHOP_PREVIEW_STYLES",
                    data: webshopPreviewStyles
                },
                new URL(iframeRef.current.src).origin
            );
        }
    };

    React.useEffect(() => {
        if (isShopIframeLoadingCompleted) {
            sendStyle();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShopIframeLoadingCompleted, props.buttonStyle, props.buyNowButtonStyle, props.linkStyle]);

    React.useEffect(() => {
        if (component.font !== globalTextFont) {
            dispatch({ type: actionTypes.WEBSHOP_FONT_FAMILY_CHANGED, payload: globalTextFont });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalTextFont]);

    const handleMessageEvents = (event: any) => {
        if (event?.data?.type) {
            const eventType = event.data.type;
            if (eventType === "FETCH_WEBSHOP_PREVIEW_STYLES") {
                setIsShopIframeLoadingCompleted(true);
            }
        }
    };

    React.useEffect(() => {
        if (!isServerPreview) {
            window.addEventListener("message", handleMessageEvents, false);
        }
        return () => {
            if (!isServerPreview) {
                window.removeEventListener("message", handleMessageEvents, false);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isServerPreview]);

    React.useEffect(() => {
        if (iframeRef.current?.contentDocument && isMVEFocus) {
            iframeRef.current.contentWindow.newWSBMVE = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iframeRef.current]);

    if (isServerPreview) {
        const webshopParams = {
            categoriesPosition: component.categoriesPosition,
            imageRatio: component.imageRatio,
            cropImages: component.cropImages,
            productsPerRow: component.productsPerRow,
            productsPerRowInMobile: component.productsPerRowInMobile,
            productsPerPage: component.productsPerPage,
            spacingItemsInMobile: component.spacingItemsInMobile,
            showCategorySelector: component.showCategory,
            userCanSortProducts: component.userCanSortProducts,
            showSearchBar: component.showSearchBar,
            buttonClassname: props.buttonClassName,
            linkClassName: props.linkClassName,
            mobileView: props.mobileView,
            labelPosition: component.labelPosition,
            buyButtonText: escapeQuotes(component.buyButtonText),
            enableBuyNowButton: component.enableBuyNowButton,
            buyNowButtonText: escapeQuotes(component.buyNowButtonText),
            buyNowButtonClassName: props.buyNowButtonClassName,
            isPaginationEnabled: true,
            embedType: "websitebuilder"
        };

        if (component.productListStyle !== ProductListStyles.Default) {
            webshopParams.productListStyle = component.productListStyle;
        }

        const shouldLoadScript = checkIfCartComponentIsNotShown(siteSettings, siteMap),
            webshopScript =
                shouldLoadScript && !isPublicPreview
                    ? `<script type="text/javascript" src="${getWebshopV2BaseUrl(props.domain, config)}/scripts/init.js?locale=${
                        props.locale
                    }"></script>`
                    : ``;
        let code = "";

        if (previewBackupTime) {
            // TODO:
            //     Once we start using translations for server side generated preview in multiple places,
            //     we would want to structure the translations and their usage in appropriate manner
            const i18nMessages = {
                "web.onlineShop": {
                    da: "Webshop",
                    de: "Webshop",
                    en: "Online Shop",
                    es: "Tienda online",
                    fi: "Verkkokauppa",
                    fr: "Boutique en ligne",
                    it: "E-commerce",
                    nb: "Nettbutikk",
                    nl: "Webwinkel",
                    pt: "Loja virtual",
                    sv: "Webbshop"
                }
            };

            const strOnlineShop = i18nMessages["web.onlineShop"][uiLocale] || i18nMessages["web.onlineShop"][DEFAULT_LOCALE_NORMALIZED];

            // Show a placeholder aligned to the middle-center
            return (
                <div
                    style={{
                        backgroundColor: "rgb(238, 238, 238, 0.6)",
                        position: "absolute",
                        top: "0",
                        right: "0",
                        left: "0",
                        bottom: "0"
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            height: "100%",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        {strOnlineShop}
                    </div>
                </div>
            );
        } else {
            // Parent Theme is added for links in webshop. Eg: .White .themeaccent = { color: accentColor };
            const webshopMainClass = `onecom-webshop-main ${autoColorMode ? selectedParentTheme : ""}`;
            code +=
                `<!--#begin code ${component.id}-->` +
                webshopScript +
                `<onecom-webshop-main
                        class='${webshopMainClass}'
                        params='${JSON.stringify(webshopParams).replace("{", "").replace("}", "")}'
                    />
                    <!--#end code ${component.id}-->`;
        }
        if (templateSelectorPreviewMode) {
            return <img src={`/screenshots/webshop/${currentPageId}/${templateSelectorPreviewMode}.png?auto=false`} />;
        }
        return (
            <div>
                <div className="web-widget" dangerouslySetInnerHTML={{ __html: code }} />
            </div>
        );
    } else {
        if (!props.buttonStyle && component.buttonId) {
            return <div />;
        }
        let componentDataForTheme;

        if (autoColorMode) {
            const themeColorsData: ThemeColorDataType = getThemeColorsFromStylesheet(globalStyles),
                /* These are actual Colors */
                {
                    fontColor: fontColorThemeDefault,
                    focusColor: focusColorThemeDefault,
                    labelBgColor: labelBgColorThemeDefault,
                    labelTextColor: labelTextColorThemeDefault,
                    hoverColor: hoverColorThemeDefault
                } = getThemeRulesForWebshop(selectedParentTheme, themeColorsData),
                /* These are theme names */
                { fontColorTheme, focusColorTheme, labelBgColorTheme, labelTextColorTheme, hoverColorTheme, promoRibbonBgColorTheme, promoRibbonTextColorTheme, } = component,
                hoverColor = themeColorsData[hoverColorTheme] || hoverColorThemeDefault,
                hoverColorWithOpacityInherited = R.assocPath([4], R.pathOr(0.2, [4], component.hoverColor), hoverColor);
            componentDataForTheme = {
                ...component,
                fontColor: themeColorsData[fontColorTheme] || fontColorThemeDefault,
                focusColor: themeColorsData[focusColorTheme] || focusColorThemeDefault,
                labelBgColor: themeColorsData[labelBgColorTheme] || labelBgColorThemeDefault,
                labelTextColor: themeColorsData[labelTextColorTheme] || labelTextColorThemeDefault,
                promoRibbonBgColor: themeColorsData[promoRibbonBgColorTheme] || labelBgColorThemeDefault,
                promoRibbonTextColor: themeColorsData[promoRibbonTextColorTheme] || labelTextColorThemeDefault,
                hoverColor: hoverColorWithOpacityInherited
            };
        }

        let locale = props.locale;
        if (component.dtLanguage) {
            locale = component.dtLanguage;
        }
        return (
            <iframe
                src={getDAL().makeWebshopPreviewUrl(
                    getQueryData({
                        component: autoColorMode ? componentDataForTheme : component,
                        buttonClassname: props.buttonClassName,
                        linkClassName: props.linkClassName,
                        buyButtonText: escapeQuotes(props.buyButtonText),
                        enableBuyNowButton: props.enableBuyNowButton,
                        buyNowButtonText: escapeQuotes(props.buyNowButtonText),
                        buyNowButtonClassName: props.buyNowButtonClassName,
                        // below styles are now passed in message event
                        buyNowButtonStyle: "",
                        buttonStyle: "",
                        linkStyle: "",
                        locale,
                        siteFonts: props.siteFonts || [], // in case it comes empty
                        mobileView: true, //local preview should always support mobile view in wsb
                        isWorkspace: !!isWorkspace,
                        isPaginationEnabled: true,
                        timestamp
                    })
                )}
                frameBorder={0}
                width="100%"
                height={heightFromWS || WEBSHOP_MIN_HEIGHT}
                ref={iframeRef}
                key={iframeRefreshCounter}
                // eslint-disable-next-line react/no-unknown-property
                featuredproducturl={featuredProduct ? featuredProduct.url : ""}
            />
        );
    }
};

export default WebshopComponent;
