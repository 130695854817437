/* eslint-disable max-len */
import React from "react";
import cx from "classnames";
import PrimaryButton from "../../../view/common/Button/PrimaryButton";
import { injectIntl } from "../../../view/intl/index";
import styles from "./view.css";
import type { UpgradeUserSubscriptionButtonType } from "../flowTypes";
import { getComponentTierDataFromComponentKind, getExternalPlansLink } from "../utils";
import { calculateUpgradeTierBeforeSeamlessUpgrade, upgrade } from "../actionCreators";
import { SubscriptionTypes } from "../../../constants/app";
import { WebshopKind } from "../../oneweb/WebShop/kind";
import { SHOW_WEBSHOP_UPGRADE } from "../../oneweb/WebShop/WebshopAdminDialog/actionTypes";
import { getAppConfig } from "../../App/epics/appConfig/appConfig.js";
import AppConfig from "../../../utils/AppConfig";
import { upgradeButtonClickedAction, upgradeDialogOpenedAction } from "../../../../../src/dashboard/src/components/Main/UpgradePlansDialog/actions";
import OpenUpgradePlanDialog from "../../TopBar/view/Upgrade/UpgradePlansDialog/OpenUpgradePlanDialog";
import { UpgradePlansDialogId } from "../../TopBar/view/dialogIds";
import BlogKind from "../../oneweb/Blog/BlogComponent/kind";
import { BLOG_UPGRADE_CLICKED } from "../../oneweb/Blog/OnboardingWizard/epic/actionTypes";
import { EventWSBSources } from "../../EventTracking/constants";

let externalPlansLink = '';
try {
    externalPlansLink = getExternalPlansLink();
} catch {
    // do nothing
}

export const UpgradeUserSubscriptionButton = injectIntl(
    ({
        intl,
        showUpgradeIcon = true,
        upgradeBtnContainerClass = "",
        isFreeUpgrade,
        dispatch,
        subscriptionData,
        componentKind,
        source
    }: UpgradeUserSubscriptionButtonType) => {
        const appConfig = AppConfig(getAppConfig());
        const isShopInAllTiersEnabled = appConfig.getOptional("oneWeb.webshop.features.enableShopInAllTier");
        const label = isFreeUpgrade ? "msg: web.UpgradeButtonFree {Free Upgrade}" : "msg: common.upgrade {Upgrade}";

        let onClickHandler;

        const dispatchShowWebshopUpgrade = () => {
            dispatch({ type: SHOW_WEBSHOP_UPGRADE });
        };

        if (externalPlansLink) {
            onClickHandler = () => {
                dispatch(OpenUpgradePlanDialog(subscriptionData, 'Webshop:MCTA'));
                dispatch(upgradeDialogOpenedAction({
                    feature: componentKind,
                    dialogId: UpgradePlansDialogId
                }));
            };
        } else if (isShopInAllTiersEnabled && componentKind === WebshopKind) {
            onClickHandler = () => {
                dispatchShowWebshopUpgrade();
            };
        } else if (!isFreeUpgrade && componentKind === WebshopKind) {
            onClickHandler = () => {
                const upgradeType = SubscriptionTypes.ECOMMERCE;
                dispatch(upgrade({ upgradeType, upgradeEventLabel: "Webshop:MCTA" }));
                dispatch(upgradeButtonClickedAction({ feature: componentKind, freeUpgrade: isFreeUpgrade, upgradeType }));
            };
        } else {
            const upgradeContentBodyMsg = getComponentTierDataFromComponentKind(componentKind).upgradeDialogMsg({
                dispatch,
                subscriptionData
            });
            onClickHandler = () => {
                dispatch(calculateUpgradeTierBeforeSeamlessUpgrade(source, componentKind, upgradeContentBodyMsg));
                if (componentKind === BlogKind) {
                    dispatch({ type: BLOG_UPGRADE_CLICKED, payload: { source: EventWSBSources.Component } });
                }
            };
        }

        return (
            <PrimaryButton
                className={cx(styles.toolBarUpgradeIconContainer, upgradeBtnContainerClass, styles.upgradeTextColor)}
                onClick={onClickHandler}
            >
                {showUpgradeIcon && <span className={styles.toolBarUpgradeIcon} />}
                {intl.msgJoint(label)}
            </PrimaryButton>
        );
    }
);
