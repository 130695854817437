import * as React from 'react';

import {
    CART_TYPE_OVERLAY
} from "../constants";
import * as colorMapper from "../../../../mappers/color";
import { doesShopComponentExists, getWebshopV2BaseUrl } from "../../../oneweb/WebShop/utils";
import { doesFeaturedProductsExist } from "../../../oneweb/FeaturedProducts/utils";
import { isAutoColorModeOn } from './utils';
import { getThemeColorsFromStylesheet } from '../../../Workspace/epics/stylesheets/selectors';

export const getCartComponent = ({
    siteSettings,
    siteMap,
    componentsDependencies,
    template, isServerPreview,
    domain,
    globalStyles,
    config
}): React.ReactElement<any> => {
    // TODO: Remove isServerPreview while implementing client side preview
    const featuredProductsExist = doesFeaturedProductsExist(siteSettings, siteMap);
    const shopComponentExists = doesShopComponentExists(siteSettings, siteMap);
    const currentLocale = componentsDependencies.WEBSHOP
        ? componentsDependencies.WEBSHOP.localeDetails.current
        : template.locale;

    if (!shopComponentExists && !featuredProductsExist) {
        return (<div data-testid="empty-cart-component" />);
    }

    const scriptUrl = (!isServerPreview) ?
        `${getWebshopV2BaseUrl(domain, config)}/scripts/preview-init.js?locale=${currentLocale}` :
        `${getWebshopV2BaseUrl(domain, config)}/scripts/init.js?locale=${currentLocale}`;

    const
        { cartData } = siteSettings,
        cartType = CART_TYPE_OVERLAY,
        { mobileBackgroundColor, cartColor } = (() => {
            if (isAutoColorModeOn(siteSettings)) {
                try {
                    const
                        themeColorsData = getThemeColorsFromStylesheet(globalStyles.stylesheets),
                        focusColorTheme = cartData.focusColorTheme,
                        { mobileBackgroundColorTheme, mobileBackgroundColorThemeDefault } = cartData,
                        mobileBackgroundColor = themeColorsData[mobileBackgroundColorTheme] ||
                            themeColorsData[mobileBackgroundColorThemeDefault];
                    return {
                        cartColor: colorMapper.toHex(themeColorsData[focusColorTheme]),
                        mobileBackgroundColor: colorMapper.toHex(mobileBackgroundColor)
                    };
                } catch (e: any) {
                    return {
                        cartColor: colorMapper.toHex(cartData.focusColor),
                        mobileBackgroundColor: colorMapper.toHex(cartData.mobileBackgroundColor),
                    };
                }
            } else {
                return {
                    cartColor: colorMapper.toHex(cartData.focusColor),
                    mobileBackgroundColor: colorMapper.toHex(cartData.mobileBackgroundColor),
                };
            }
        })(),
        cartParams = {
            cartType,
            cartColor,
            /*flatBackgroundColor: 'transparent',*/ // no need. Needed only when cartType: Flat
            overlayBackgroundColor: mobileBackgroundColor,
            showMobileCart: cartData.showMobileCart,
            mobileBackgroundColor,
            overlayPosition: cartData.overlayPosition
        },
        /**
         * Id is necessary as the complete comment is expected by the regex in server to skip the code from
         * minimizing and optimization.
         */
        // If you copy the code, please ensure to change the ID
        /**
         * TODO: The font family in the <style> added in below snippet should be the font family from to shop component
         * or featured product component if no shop component.
         * This font can also be instead passed as a param in <onecom-webshop-cart>
         * For now just adding default font to fix WEBSHOP-11499
         */
        cartCode = `<!--#begin code 00000000-0000-0000-0000-000000000000-->
            <style type="text/css">
                .ws-app-modal {
                    font-family: "Helvetica Neue", Helvetica, sans-serif
                }
            </style>
            <script type="text/javascript" src="${scriptUrl}"></script>
            <onecom-webshop-cart
                class="onecom-webshop-cart"
                params='${JSON.stringify(cartParams).replace("{", '').replace("}", '')}'
            >
            <!--#end code 00000000-0000-0000-0000-000000000000-->`;

    return (
        <div className="web-widget" dangerouslySetInnerHTML={{ __html: cartCode }} />
    );
};
